import "../../../../stylesheets/campaign";
import "../../../../stylesheets/fundraising";
import "../../../../stylesheets/templates/fundraising/template-1";
import "../../../../stylesheets/templates/fundraising/template-2";
import "../../../../stylesheets/templates/fundraising/template-3";
import "../../../../stylesheets/templates/fundraising/template-4";
import "../../../../stylesheets/templates/fundraising/template-5";
import "../../../../stylesheets/templates/fundraising/template-1-mobile";
import "../../../../stylesheets/templates/fundraising/template-2-mobile";
import "../../../../stylesheets/templates/fundraising/template-3-mobile";
import { pickTextColorBasedOnBgColorAdvanced } from "../../../utils";

import "./index";

var locale = window.locale || "es";

$(document).ready(function () {
  $("#payroll_donator_tax_id").on("input", ({ target }) => {
    let val = format(target.value);
    target.value = val;
  });
  $("#payroll-donator-submit-button").on("click", (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = $("#new_payroll_discount");
    form[0].classList.add("was-validated");
    const rutField = $("#payroll_donator_tax_id")[0];
    const rutValidity = rutField ? Fn.validaRut(rutField.value) : true;
    if (rutValidity === false) {
      $("#payroll_donator_tax_id").addClass("is-invalid");
      $("#payroll_donator_tax_id").removeClass("is-valid");
      $("#new_payroll_discount").removeClass("was-validated");
    } else {
      $("#payroll_donator_tax_id").addClass("is-valid");
      $("#payroll_donator_tax_id").removeClass("is-invalid");
      $("#new_payroll_discount").addClass("was-validated");
    }
    if (form[0].checkValidity() === true && rutValidity === true) {
      $("#new_payroll_discount").submit();
    }
  });

  $("#recurring-checkbox").change(function () {
    if (this.checked) {
      $("#donator_email_label").html(
        locale == "en" ? "Enter your email" : "Ingresa tu mail"
      );
    } else {
      $("#donator_email_label").html(
        locale == "en"
          ? "Enter your email <span>(Optional)</span>"
          : "Ingresa tu mail <span id='optional'>(Opcional)</span>"
      );
    }
  });

  let modal_primary_color = $("#volunteer-stats").attr("data-primary-color");
  let modal_secondary_color = $("#volunteer-stats").attr(
    "data-secondary-color"
  );
  if (modal_primary_color && modal_secondary_color) {
    let color = pickTextColorBasedOnBgColorAdvanced(
      modal_primary_color,
      modal_primary_color,
      modal_secondary_color
    );
    $("#volunteer-stats")[0].style.setProperty("--modal-color", color);
  }
});

if (screen.width <= 768) {
  $("form#new_donator")[0].remove();
} else {
  $("form#new_donator")[1].remove();
}
