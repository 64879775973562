import "../../../../stylesheets/payment_methods/mercadopago";

var mp = window.mp;

document.addEventListener("DOMContentLoaded", () => {
  if (mp) {
    const cardForm = mp.cardForm({
      amount: "100.5",
      iframe: true,
      form: {
        id: "form-checkout",
        cardNumber: {
          id: "form-checkout__cardNumber",
          placeholder: "Número de tarjeta",
        },
        expirationDate: {
          id: "form-checkout__expirationDate",
          placeholder: "MM/YY",
        },
        securityCode: {
          id: "form-checkout__securityCode",
          placeholder: "Código de seguridad",
        },
        cardholderName: {
          id: "form-checkout__cardholderName",
          placeholder: "Titular de la tarjeta",
        },
        issuer: {
          id: "form-checkout__issuer",
          placeholder: "Banco emisor",
        },
        installments: {
          id: "form-checkout__installments",
          placeholder: "Cuotas",
        },
        identificationType: {
          id: "form-checkout__identificationType",
          placeholder: "Tipo de documento",
        },
        identificationNumber: {
          id: "form-checkout__identificationNumber",
          placeholder: "Número del documento",
        },
        cardholderEmail: {
          id: "form-checkout__cardholderEmail",
          placeholder: "E-mail",
        },
      },
      callbacks: {
        onFormMounted: (error) => {
          if (error)
            return console.warn("Form Mounted handling error: ", error);
        },
        onSubmit: (event) => {
          event.preventDefault();

          const {
            paymentMethodId: payment_method_id,
            issuerId: issuer_id,
            cardholderEmail: email,
            amount,
            token,
            installments,
            identificationNumber,
            identificationType,
          } = cardForm.getCardFormData();
          $.ajax({
            type: "POST",
            url: window.mercadopago_url,
            data: {
              token,
              issuer_id,
              payment_method_id,
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: "Donación puntual",
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
            },
          }).done(function (response) {
            window.location.href = response["url"];
          });
        },
        onFetching: (resource) => {
          // Animate progress bar
          const progressBar = document.querySelector(".progress-bar");
          progressBar.removeAttribute("value");

          return () => {
            progressBar.setAttribute("value", "0");
          };
        },
      },
    });
  }
});

// console.log($("#cardNumber"))
// $(document).ready(function () {
//   console.log($("#cardNumber"))
//   $("#cardNumber").on("input", ({ target }) => {
//     console.log('entre')
//     console.log(this.value);
//     $(".issuer_selector")[0].value
//     let card_type_dictionary = {169: "anex"};
//     let card_type = card_type_dictionary[$(".issuer_selector")[0].value];
//     console.log(card_type)
//     const card_image = document.getElementById("card-type-image");
//     card_image.src = `https://storage.googleapis.com/fundingplatform-files/assets/icons/${card_type}-icon.png`;
//   });
// });

// $(".issuer_selector").change(function () {
//   console.log(this.value);
// });

// import JQuery from "jquery";
// window.$ = window.JQuery = JQuery;
