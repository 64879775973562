import "select2";
import "select2/dist/css/select2.css";
import "../../../../javascript/stylesheets/application"

$(".choices-selection").select2({
  placeholder: "Seleccione una o varias opciones",
  allowClear: true,
});

$(".select-location").select2({
  placeholder: "Selecciona tu programa...",
});

$(".select-region").select2({
  placeholder: "Selecciona tu región...",
});
