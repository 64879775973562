require("bootstrap");
import AutoNumeric from "autonumeric";
import { format } from "rut.js";
import * as utils from "../../utils";
import "../selectors";
import {
  pickTextColorBasedOnBgColorAdvanced,
  Fn,
  validateFormData,
  basicValidation,
  updateDynamicColorElements,
} from "../../../utils";
import { formatPhoneNumber, loadPhoneNumber } from "../../../backoffice/utils";
import { paymentMethodResponse } from "./payment_methods_response";

$(document).ready(function () {
  var subscription_type = window.subscription_type || "once";
  var currency_type = window.currency_type || "clp";
  var locale = window.locale || "es";
  var recaptcha_site_key = window.recaptcha_site_key;
  let fintoc_bank = null;
  let holderType = null;

  const min_amount_json_default = {
    uf: "0.02",
    clp: "1000",
    cop: "5000",
    eur: "1",
    usd: "1",
    pen: "5",
  };
  var disabled = false;
  let currency = currency_type;
  var type = subscription_type;
  window.donation_type = type;
  var amountin;
  loadPhoneNumber();
  basicValidation();
  utils.changeRecommendedAmountText(type, currency);
  utils.donandoFooterColor();
  $("#donator_donation_transactions_attributes_0_amount").on(
    "wheel",
    function (e) {
      $(this).blur();
    }
  );
  var platform_title = document.querySelector("#basic-platform-title");
  var standard_landing_title = document.querySelector(
    "#standard-landing-title"
  );

  var targets = $(".dynamic-color");
  updateDynamicColorElements(targets);

  $("#donator_phone_number").on("keypress", function (e) {
    if (e.which == 32) {
      return false;
    }
  });

  $(".email-field-input").on("input", function (e) {
    var id = this.id.split("-").slice(-1)[0];
    this.value = this.value.replaceAll(" ", "");
    utils.validateEmail(`email-${id}`, `email-confirmation-${id}`);
  });

  // Donation type selector
  $(".donation-type-select button").click(({ target }) => {
    type = target.getAttribute("data-type");
    window.donation_type = type;
    var single_optional_email = $("#email_form").attr(
      "data-single-email-optional"
    );
    let min_amount_json;
    if ($("#amount_form").attr("data-min-amount")) {
      min_amount_json = JSON.parse($("#amount_form").attr("data-min-amount"));
    } else {
      min_amount_json = min_amount_json_default;
    }
    utils.managePaymentOptions(currency, type);
    if (type === "subscription") {
      $('[name="recurring_radio"]#subscription')[0].checked = true;
      utils.subscriptionTypeSettings(locale, min_amount_json, currency);
    } else if (type === "once") {
      $('#single-donation[name="recurring_radio"]')[0].checked = true;
      utils.onceTypeSetting(currency, type, amountin, single_optional_email);
    } else if (type === "cards") {
      utils.cardsTypeSetting(locale);
    } else if (type === "charity-wreaths") {
      utils.cards2TypeSetting(locale);
    } else if (type === "projects") {
      utils.projectsTypeSetting(locale);
    } else if (type === "causes") {
      utils.causesTypeSetting();
    }
  });

  // Currency selector
  $(".currency-selection label").click(({ target }) => {
    currency = target.getAttribute("data-currency");
    utils.setCurrency(currency, type, amountin);
  });

  // Method selector
  $(".payment-selection .payment-button").click(({ currentTarget }) => {
    const kind = currentTarget.getAttribute("data-kind");
    fintoc_bank = currentTarget.getAttribute("data-bank");
    holderType = currentTarget.getAttribute("data-holder");
    let fintoc_status = currentTarget.getAttribute("data-status");
    currency = currentTarget.hasAttribute("data-currency")
      ? currentTarget.getAttribute("data-currency")
      : currency;
    $("#currency-type").val(currency);
    if (!kind) return;
    if (kind != "fintoc_payment" || fintoc_bank) {
      $(`input:radio[value="${kind}"]`).prop("checked", true);
    }
    $("input:hidden.transaction_kind").val(kind);
    if (
      kind == "fintoc_payment" &&
      !fintoc_bank &&
      fintoc_status == "inactive"
    ) {
      currentTarget.style.border = "solid 2px";
      $(".fintoc-payment-button").removeClass("hidden-input");
      currentTarget.setAttribute("data-status", "active");
      $(".fintoc-arrow").css("rotate", "90deg");
    } else if (
      kind == "fintoc_payment" &&
      !fintoc_bank &&
      fintoc_status == "active"
    ) {
      currentTarget.style.border = "none";
      $(".fintoc-payment-button").addClass("hidden-input");
      currentTarget.setAttribute("data-status", "inactive");
      $(".fintoc-arrow").css("rotate", "0deg");
    } else {
      $("#new_donator").submit();
    }
  });

  // Recurring radio
  $('[name="recurring_radio"]').change(function () {
    var single_optional_email = $("#email_form").attr(
      "data-single-email-optional"
    );
    let min_amount_json;
    if ($("#amount_form").attr("data-min-amount")) {
      min_amount_json = JSON.parse($("#amount_form").attr("data-min-amount"));
    } else {
      min_amount_json = min_amount_json_default;
    }
    if (this.value == "subscription") {
      type = "subscription";
      utils.managePaymentOptions(currency, type);
      utils.subscriptionTypeSettings(locale, min_amount_json, currency);
    } else {
      type = "once";
      utils.managePaymentOptions(currency, type);
      utils.onceTypeSetting(currency, type, amountin, single_optional_email);
      $(".currency-selection label[data-currency='usd']").removeClass("active");
    }
  });

  $("#donator_donation_transactions_attributes_0_amount").on("input", (e) => {
    utils.validateAmountToDonate(type, currency);
    $(`div.block-option`).removeClass("active-option");
  });

  amountin = new AutoNumeric.multiple(
    "#donator_donation_transactions_attributes_0_amount",
    {
      digitGroupSeparator: ".",
      decimalCharacter: ",",
      decimalPlaces: 0,
    }
  );
  // End autonumeric format amount input

  $("#donator_tax_id").on("input", ({ target }) => {
    let val = format(target.value);
    target.value = val;
  });

  $("#donator_tax_id").on("blur", ({ target }) => {
    const rutField = $("#donator_tax_id")[0];
    const rutValidity = rutField ? Fn.validaRut(rutField.value) : true;
    utils.rutFormValidity(rutValidity, $("#new_donator"), $("#donator_tax_id"));
  });

  $(".tax_id_input").on("input", ({ target }) => {
    let val = format(target.value);
    target.value = val;
  });

  $(".tax_id_input").on("blur", ({ target }) => {
    const rutField = $(".tax_id_input")[0];
    const rutValidity = rutField ? Fn.validaRut(rutField.value) : true;
    utils.rutFormValidity(rutValidity, $("#new_donator"), $(".tax_id_input"));
  });

  $(".iti__country-list li").click(function () {
    this?.parentElement?.parentElement?.nextSibling?.setAttribute(
      "data-code",
      $(this).data("dial-code")
    );
    formatPhoneNumber();
  });

  $("#phone_number_hidden_input").change(function (e) {
    formatPhoneNumber();
  });

  // Handle form submit
  $("#new_donator").on("submit", (event) => {
    event.preventDefault();
    event.stopPropagation();
    // dentro del listener del submit
    if (disabled) return;
    disabled = true;
    setTimeout(() => {
      disabled = false;
    }, 1500);
    const form = $("#new_donator");
    form[0].classList.add("was-validated");
    const url = form.attr("action");
    const rutField = $("#donator_tax_id")[0] || $(".tax_id_input")[0];
    const rutValidity = rutField ? Fn.validaRut(rutField.value) : true;
    formatPhoneNumber();
    // parse amount value, to correct format
    const amountInput = document.getElementById(
      "donator_donation_transactions_attributes_0_amount"
    );
    let current_currency = $("#currency-type")[0].value;
    const amount_validate = utils.validateAmountToDonate(
      type,
      current_currency
    );
    amountInput.value = amount_validate.amount || "";
    if ($("#donator_street_user_opportunities_attributes_0_street_user_id")) {
      $("#donator_prospect_amount").val(
        JSON.stringify({
          amount: amountInput.value,
          currency: current_currency,
        })
      );
    }
    var validMail = $("#donator_email")[0]
      ? $("#donator_email")[0].checkValidity()
      : false;
    if (!validMail && $("#donator_email")[0] === undefined) validMail = true;

    // Validates that one project is selected
    const projectForm = $("#project_form")[0];
    const isProjectSelected =
      $("input[type=radio]:checked").length != 0 ? true : false;
    isProjectSelected
      ? $("#project_form .invalid-feedback").hide()
      : $("#project_form .invalid-feedback").show();

    const payment_radio_btns =
      $(".payment-button input[type=radio]").length != 0;
    const is_payment_radio_btns_checked =
      $(".payment-button input[type=radio]:checked").length != 0;
    if (payment_radio_btns && !is_payment_radio_btns_checked) {
      alert("Selecciona un método de pago");
      return;
    }
    const projectValidate = projectForm ? isProjectSelected : true;
    grecaptcha.enterprise.ready(function () {
      grecaptcha.enterprise
        .execute(recaptcha_site_key, { action: "submit" })
        .then(function (token) {
          let captcha = $(`input[name="recaptcha_token"`);
          for (var i = 0; i < captcha.length; i++) {
            captcha[i].value = token;
          }
          if (
            form[0].checkValidity() === true &&
            amount_validate.valid === true &&
            (utils.rutFormValidity(
              rutValidity,
              $("#new_donator"),
              $(".tax_id_input")
            ) === true ||
              utils.rutFormValidity(
                rutValidity,
                $("#new_donator"),
                $("#donator_tax_id")
              ) === true) &&
            validMail === true &&
            projectValidate === true &&
            utils.checkEmailConfirmation() === true
          ) {
            if (
              $(".transaction_kind").val() != "manual_transfer" ||
              $(".save-inscription-btn")
            )
              $("#loader").show();
            else $("#transfer-modal").modal("show");
            $.ajax({
              type: "POST",
              url: url,
              data: form.serialize(), // serializes the form's elements.
            })
              .done(function (response) {
                paymentMethodResponse(response, holderType, fintoc_bank);
              })
              .fail(function (response) {
                $("#loader").hide();
                alert(
                  "Ha ocurrido un problema, te pedimos por favor reintentar la transacción"
                );
                // return location.reload();
              });
            return false;
          } else {
            console.log("Validation not going through");
            console.log(
              "Form checkValidity: " +
                (form[0].checkValidity() === true).toString()
            );
            console.log(
              "TaxID not required: " + !$("#donator_tax_id").required
            );
            if (form[0].checkValidity() === false) {
              validateFormData(form[0]);
            }
          }
        });
    });
  });
});
if ($("#finalized").html() == "true" || $("#error").html() == "true") {
  if (locale == "en") {
    $("#thanks-msg").html("Thanks for your contribution!");
    $("#thanks-text").html(
      'We appreciate your contribution, which allows us together to fulfill the dream of "Empowering women as recreators of the world and thereby contribute to a more inclusive and equalitarian society"'
    );
    $("#thanks-social").html("Follow us on our social networks!");
    $("#error-msg").html("We are sorry! Please try again.");
    $("#error-text").html(
      "An error occurred in the transaction, so your donation could not be processed"
    );
    $("#error-button").html("Retry");
  }
}
window.addEventListener("pageshow", function () {
  var perfEntries = performance.getEntriesByType("navigation");
  if (perfEntries[0].type === "back_forward") {
    location.reload();
  }
});
