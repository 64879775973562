import "../payments/mercadopago";

export const paymentMethodResponse = (response, holderType, fintoc_bank) => {
  if (response["payment_stream"] == "webpay") {
    const url = response["url"];
    const token = response["token"];
    const form = $(
      '<form action="' +
        url +
        '" method="post">' +
        '<input type="hidden" name="' +
        response["name"] +
        '" value="' +
        token +
        '" />' +
        "</form>"
    );
    $("body").append(form);
    form.submit();
  } else if (response["payment_stream"] == "khipu") {
    window.location.href = response["url"];
  } else if (response["payment_stream"] == "flow_servipag") {
    window.location.href = response["url"];
  } else if (response["payment_stream"] == "payroll_discount_payment") {
    $("#loader").hide();
    $("#payroll_transaction_uuid").val(response["transaction_id"]);
    $("#payroll_donator_email").val(response["email"]);
    $("#payroll-discount-modal").modal("show");
  } else if (response["payment_stream"] == "payroll_discount_subscription") {
    $("#loader").hide();
    $("#payroll_transaction_uuid").val(response["transaction_id"]);
    $("#payroll_donator_email").val(response["email"]);
    $("#payroll-discount-modal").modal("show");
  } else if (response["payment_stream"] == "paypal") {
    const form = $(response["form"]);
    $("body").append(form);
    form.submit();
  } else if (response["payment_stream"] == "paypal_payment") {
    $("#loader").hide();
    $("#paypal-payment-modal").modal("show");
    paypal
      .Buttons({
        env: response["env"],
        createOrder: function () {
          return response["token"];
        },
        onApprove: function (data) {
          $("#paypal-payment-modal").modal("hide");
          $("#loader").show();
          return $.post("/paypal/capture_order", {
            paymentToken: data.orderID,
          }).then(function (executeData) {
            window.location.replace(
              response["completed_url"] + "/?dt_uuid=" + response["dt_uuid"]
            );
          });
        },
        onCancel: function (data) {
          $("#paypal-payment-modal").modal("hide");
          $("#loader").show();
          window.location.replace(
            response["cancel_url"] + "/?dt_uuid=" + response["dt_uuid"]
          );
        },
      })
      .render("#submit-paypal-payment");
  } else if (response["payment_stream"] == "paypal_subscription") {
    $("#loader").hide();
    $("#paypal-payment-modal").modal("show");
    paypal
      .Buttons({
        env: response["env"],
        createOrder: function () {
          return response["token"];
        },
        onApprove: function (data) {
          $("#paypal-modal").modal("hide");
          $("#loader").show();
          return $.post("/paypal/execute_subscription", {
            subscriptionToken: data.orderID,
          }).then(function (executeData) {
            window.location.replace(response["completed_url"]);
          });
        },
        onCancel: function (data) {
          $("#paypal-modal").modal("hide");
          $("#loader").show();
          window.location.replace(
            response["cancel_url"] + "/?dt_uuid=" + response["dt_uuid"]
          );
        },
      })
      .render("#submit-paypal-payment");
  } else if (response["payment_stream"] == "mach") {
    window.location.href = response["url"];
  } else if (response["payment_stream"] == "manual_transfer") {
    if (response["url"]) {
      window.location.href = response["url"];
    }
  } else if (response["refresh"] === true) {
    window.scrollTo(0, 0);
    window.location.reload();
  } else if (response["payment_stream"] == "fintoc_payment") {
    const widget = Fintoc.create({
      widgetToken: response["widget_token"],
      publicKey: response["public_key"],
      holderType: holderType || "individual",
      product: "payments",
      institutionId: fintoc_bank,
      webhookUrl: response["webhook_url"],
      onSuccess: () => {
        window.location.href = response["success_url"];
      },
      onExit: () => {
        alert("Transacción no realizada, por favor intenta nuevamente");
        $("#loader").hide();
      },
    });
    widget.open();
  } else if (response["payment_stream"] == "fintoc_subscription") {
    const widget = Fintoc.create({
      widgetToken: response["widget_token"],
      publicKey: response["public_key"],
      holderType: "individual",
      product: "subscriptions",
      webhookUrl: response["webhook_url"],
      onSuccess: () => {
        window.location.href = response["success_url"];
      },
      onExit: () => {
        alert("Ocurrió un error, por favor intenta nuevamente");
        $("#loader").hide();
      },
    });
    widget.open();
  } else if (response["payment_stream"] == "getnet_transaction") {
    window.location.href = response["url"];
  } else if (response["payment_stream"] == "mercadopago_payment") {
    $("#loader").hide();
    window.mercadopago_url = response["url"];
    $("#mercadopago-payment-modal").modal("show");
    if ($("#mercadopago-payment")) {
      $("#mercadopago-payment").show();
    }
    document.getElementById("form-checkout__cardholderEmail").value =
      response["donator_email"] || "";
    document
      .getElementById("form-checkout__submit")
      .classList.add("btn", "btn-primary");
    document.getElementById("form-checkout__installments").style["display"] =
      "none";
  } else if (response["payment_stream"] == "epayco_payment") {
    $("#loader").hide();
    var handler = ePayco.checkout.configure({
      key: response["key"],
      test: response["test"],
    });
    var data = {
      name: "Donación",
      description: `Aporte a ${response["account_name"]}`,
      currency: response["currency"],
      amount: response["amount"],
      email_billing: response["donator_email"],
      address_billing: response["donator_address"],
      country: "co",
      confirmation: response["webhook_url"],
      response: response["return_url"],
      methodconfirmation: "post",
      mobilephone_billing: response["donator_phone"],
      name_billing: response["donator_name"],
      number_doc_billing: response["donator_tax_id"],
    };
    handler.open(data);
  } else if (response["payment_stream"] == "izipay_payment") {
    window.location.href = response["url"];
  } else if (response["payment_stream"] == "invalid_email") {
    alert("Mail inválido, por favor inténtelo de nuevo.");
    $("#loader").hide();
  } else if (response["payment_stream"] == "existing_subscription") {
    alert("Este mail ya está registrado con una suscripción.");
    $("#loader").hide();
  } else if (response["payment_stream"] == "existing_inscription") {
    alert("Este mail ya está registrado con una inscripción.");
    $("#loader").hide();
  } else if (response["payment_stream"] == "authentication_error") {
    alert("Error al verificar captcha.");
    $("#loader").hide();
  } else if (response["payment_stream"] == "full_product_event") {
    alert(
      `No quedan cupos suficientes en este producto. Sólo quedan ${response["available_prod"]} disponible(s).`
    );
    $("#loader").hide();
  } else {
    $("#loader").hide();
    alert(
      "Ha ocurrido un problema, te pedimos por favor reintentar la transacción"
    );
  }
};
