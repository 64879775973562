import "../../../../stylesheets/products";
import AutoNumeric from "autonumeric";
import { managePaymentOptions } from "../../utils";

$(document).ready(function () {
  if (
    window.performance &&
    window.performance.navigation.type ===
      window.performance.navigation.TYPE_BACK_FORWARD
  ) {
    $("#product-quantity-desktop").attr("autocomplete", "off");
    $("#product-quantity-mobile").attr("autocomplete", "off");
  }

  // Cause Products
  $("#product-quantity-desktop").on("change", function (target) {
    let total_amount;
    if ($("#currency-type")[0].value == "clp") {
      total_amount =
        target.target.getAttribute("data-price") *
        $("#product-quantity-desktop").val();
    } else if ($("#currency-type")[0].value == "usd") {
      total_amount =
        target.target.getAttribute("data-price-usd") *
        $("#product-quantity-desktop").val();
    }
    update_product_amount(total_amount);
    $("#product-quantity-mobile").val($("#product-quantity-desktop").val());
  });

  $("#product-quantity-mobile").on("change", function (target) {
    let total_amount;
    if ($("#currency-type")[0].value == "clp") {
      total_amount =
        target.target.getAttribute("data-price") *
        $("#product-quantity-mobile").val();
    } else if ($("#currency-type")[0].value == "usd") {
      total_amount =
        target.target.getAttribute("data-price-usd") *
        $("#product-quantity-mobile").val();
    }
    update_product_amount(total_amount);
    $("#product-quantity-desktop").val($("#product-quantity-mobile").val());
  });
});
export const update_product_currency = (currency) => {
  let total_amount;
  if (currency == "clp") {
    total_amount =
      $("#product-quantity-desktop")[0].getAttribute("data-price") *
      $("#product-quantity-desktop").val();
    $("#unitary-price")[0].innerText = new Intl.NumberFormat("es-CL", {
      currency: "CLP",
      style: "currency",
      maximumFractionDigits: 0,
    }).format($("#unitary-price")[0].getAttribute("clp-price"));
    $(".usd-ticket-price").hide();
    $(".clp-ticket-price").show();
  } else if (currency == "usd") {
    total_amount =
      $("#product-quantity-desktop")[0].getAttribute("data-price-usd") *
      $("#product-quantity-desktop").val();
    $("#unitary-price")[0].innerText = new Intl.NumberFormat("es-CL", {
      currency: "USD",
      style: "currency",
      maximumFractionDigits: 0,
    }).format($("#unitary-price")[0].getAttribute("usd-price"));
    $(".usd-ticket-price").show();
    $(".clp-ticket-price").hide();
  }
  update_product_amount(total_amount);
  return total_amount;
};
export const update_product_amount = (total_amount) => {
  $("#total_amount_product_desktop").text(total_amount);
  $("#total_amount_product_mobile").text(total_amount);
  $("#donator_donation_transactions_attributes_0_amount")[0].setAttribute(
    "value",
    total_amount
  );
  new AutoNumeric.multiple("#total_amount_product_desktop", {
    digitGroupSeparator: ".",
    decimalCharacter: ",",
    decimalPlaces: 0,
    currencySymbol: "$",
  });
  new AutoNumeric.multiple("#total_amount_product_mobile", {
    digitGroupSeparator: ".",
    decimalCharacter: ",",
    decimalPlaces: 0,
    currencySymbol: "$",
  });
};
export const manageProductsCurrencies = (
  new_product_price,
  new_product_price_usd
) => {
  let local_currency = $("#local-currency")?.[0]?.innerHTML;
  if (!local_currency) {
    return;
  }
  if (
    $("#currency-type")[0].value == "usd" &&
    parseInt(new_product_price_usd) > 0
  ) {
    if (!(parseInt(new_product_price) > 0)) {
      $(".currency_container").hide();
    } else {
      $(".currency_container").show();
    }
  } else if (
    $("#currency-type")[0].value == "usd" &&
    !(parseInt(new_product_price_usd) > 0)
  ) {
    managePaymentOptions(local_currency, "once");
    $(".currency_container").hide();
  } else if (
    $("#currency-type")[0].value == local_currency &&
    parseInt(new_product_price) > 0
  ) {
    if (!(parseInt(new_product_price_usd) > 0)) {
      $(".currency_container").hide();
    } else {
      $(".currency_container").show();
    }
  } else if (
    $("#currency-type")[0].value == local_currency &&
    !(parseInt(new_product_price) > 0)
  ) {
    managePaymentOptions("usd", "once");
    $(".currency_container").hide();
  }
};
