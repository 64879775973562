import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

const replaceOnHTML = (response) =>
  Object.keys(response).forEach((response_element) => {
    let new_response_element = response_element.replaceAll("/", "-");
    $(`.${new_response_element}`).each((element, data) => {
      data.innerHTML = response[response_element] || "0";
      let max = response["max"];
      if (max) {
        let opacity = response[response_element].replace(/\D/g, "") / max;
        data.style.backgroundColor = `rgba(156, 117, 207, ${opacity})`;
      }
      let radial_progress_percentage = response["radial_progress_percentage"];
      if (radial_progress_percentage) {
        let percentage = parseFloat(
          radial_progress_percentage.replace("%", "").replace(",", ".")
        );
        let left_deg = percentage > 50 ? ((percentage - 50) * 18) / 5 : 0;
        let right_deg = percentage > 50 ? 180 : (percentage * 18) / 5;
        $(`.circle-progress`).each((element, data) => {
          data.style.setProperty("--left-deg", `${left_deg}deg`);
          data.style.setProperty("--right-deg", `${right_deg}deg`);
        });
      }
    });
  });

export const getData = async (
  route,
  loading,
  data = {},
  { formatResponse } = {}
) => {
  return new Promise((resolve, reject) => {
    $.ajax({
      type: "GET",
      url: route,
      data: data,
    })
    .done(function (responseData) {
      $(`.${loading}`).hide();
      let response = responseData["response"];
      response = typeof response === "string" ? JSON.parse(response) : response;
      if (response instanceof Array) {
        response.forEach((element) => {
          if (formatResponse) {
            element = formatResponse(element);
          }
          replaceOnHTML(element);
        });
      } else {
        if (formatResponse) {
          response = formatResponse(response);
        }
        replaceOnHTML(response);
      }
      resolve(responseData);
    })
    .fail(function (jqXHR, textStatus, errorThrown) {
      // Rechazando la promesa en caso de error
      reject(errorThrown);
    });
  });
};

// (response: { [key: string]: any }, fields: string[], replaceId: string) => { [key: string]: any }
export const replaceRequestFieldsWithId = (response, fields, replaceId) => {
  const newObject = {};
  Object.keys(response).forEach((key) => {
    let newKey;
    if (fields.includes(key)) {
      newKey = `${key}_${replaceId}`;
    } else {
      newKey = key;
    }
    newObject[newKey] = response[key];
  });
  return newObject;
};

export const getChartData = async (route, loading, styles, type = "column") => {
  $.ajax({
    type: "GET",
    url: route,
  }).done(function (responseData) {
    $(`.${loading}`).hide();
    let response = responseData["response"];
    if (type == "column") {
      new Chartkick.ColumnChart(response["id"], response["data"], styles);
    } else if (type == "line") {
      new Chartkick.LineChart(response["id"], response["data"], styles);
    }
  });
};

export const getDataAndChartData = async (route, loading, styles) => {
  $.ajax({
    type: "GET",
    url: route,
  }).done(function (responseData) {
    $(`.${loading}`).hide();
    let response = responseData["response"];
    Object.keys(response).forEach((response_element) => {
      let new_response_element = response_element.replaceAll("/", "-");
      $(`.${new_response_element}`).each((element, data) => {
        data.innerHTML = response[response_element] || "0";
        let max = response["max"];
        if (max) {
          let opacity = response[response_element].replace(/\D/g, "") / max;
          data.style.backgroundColor = `rgba(156, 117, 207, ${opacity})`;
        }
        let radial_progress_percentage = response["radial_progress_percentage"];
        if (radial_progress_percentage) {
          let percentage = radial_progress_percentage.replace("%", "");
          let left_deg = percentage > 50 ? ((percentage - 50) * 18) / 5 : 0;
          let right_deg = percentage > 50 ? 180 : (percentage * 18) / 5;
          $(`.circle-progress`).each((element, data) => {
            data.style.setProperty("--left-deg", `${left_deg}deg`);
            data.style.setProperty("--right-deg", `${right_deg}deg`);
          });
        }
      });
    });

    let response_chart = responseData["response_chart"];
    new Chartkick.LineChart(
      response_chart["id"],
      response_chart["data"],
      response_chart["styles"]
    );
  });
};

export const loadPhoneNumber = () => {
  var country_code = window.country_code || "cl";
  let phoneInputField = document.getElementsByClassName("phone-number-input");
  if (phoneInputField) {
    for (var i = 0; i < phoneInputField.length; i++) {
      if (phoneInputField[i]) {
        var iti = intlTelInput(phoneInputField[i], {
          utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
          separateDialCode: true,
          preferredCountries: [country_code],
        });
      }
      const countryData = iti.getSelectedCountryData();
      phoneInputField[i].setAttribute("data-code", countryData["dialCode"]);
    }
  }
  formatPhoneNumber();
};

export const formatPhoneNumber = () => {
  let phoneInputField = document.getElementsByClassName("phone-number-input");
  if (phoneInputField) {
    for (var i = 0; i < phoneInputField.length; i++) {
      let countryData = phoneInputField[i].getAttribute("data-code");
      let phoneNumber = phoneInputField[i].value.replaceAll(" ", "");
      let phoneId = phoneInputField[i].getAttribute("phone-id")
        ? "-" + phoneInputField[i].getAttribute("phone-id")
        : "";
      $(`#phone-number${phoneId}`).val("+" + countryData + phoneNumber);
      phoneInputField[i].value = phoneNumber;
    }
  }
};

export const formatIntField = () => {
  $.map($(".int_field"), function (input) {
    input.value = input.value.replaceAll(".", "");
  });
};

export const validateFormData = (form_hash) => {
  let reversed_array = Object.keys(form_hash);
  reversed_array.reverse();
  reversed_array.shift();
  reversed_array.forEach(function (element) {
    const field = form_hash[element];
    if (field.checkValidity() === false) {
      field.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });
};

export const getProgressBarData = async (route, loading) => {
  $.ajax({
    type: "GET",
    url: route,
  }).done(function (responseData) {
    $(`.${loading}`).hide();
    let response = responseData["response"];
    Object.keys(response).forEach((response_element) => {
      if ($(`.${response_element}`)[0]) {
        $(`.${response_element}`)[0].innerHTML =
          response[response_element] || 0;
        $(`.progress-bar.${response_element}`)[0].style.width =
          response[response_element] || "0%";
        $(`.progress.${response_element}`).show();
      }
    });
  });
};

export const productsValidation = () => {
  "use strict";
  window.addEventListener(
    "load",
    function () {
      // Fetch all the forms we want to apply custom Bootstrap validation styles to
      var forms = document.getElementsByClassName("products-needs-validation");
      // Loop over them and prevent submission
      var validation = Array.prototype.filter.call(forms, function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            var rows = form.querySelectorAll(".nested-fields");
            rows.forEach(function (row) {
              var currencies = row.querySelectorAll('[name*="[prices"]');
              var present_value = false;
              currencies.forEach(function (currency) {
                if (parseInt(currency.value) > 0) {
                  present_value = true;
                } else {
                  currency.value = "";
                }
              });
              currencies.forEach(function (currency) {
                if (present_value) {
                  currency.removeAttribute("required");
                } else {
                  currency.setAttribute("required", true);
                }
              });
            });
            if (form.checkValidity() === false) {
              event.preventDefault();
              event.stopPropagation();
            } else {
              $.map($('.int_field[name*="[prices"]'), function (input) {
                input.value = input.value.replaceAll(".", "");
              });
            }
            form.classList.add("was-validated");
          },
          false
        );
      });
    },
    false
  );
};

export const genericRequest = async (route, callbackFunction) => {
  $.ajax({
    type: "GET",
    url: route,
  }).done(callbackFunction);
};
